/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/js-cookie@3.0.5/dist/js.cookie.min.js
 * - /npm/lightbox2@2.11.5/dist/js/lightbox.min.js
 * - /npm/bootstrap@5.3.5/dist/js/bootstrap.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
